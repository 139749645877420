import React from 'react';
import styles from './SectionHero.module.scss';

import imageData from './imageData';

const SectionHero = () => {
  return (
    <section className={styles.SectionHero}>
      <div className={styles.sloganContainer}>
        <h1 className={styles.title}>
        <div className={styles.firstLine}>
          Activating <span className={styles.green}>Data</span>
        </div>
        <div className={styles.secondLine}>from Documents</div>
      </h1>
        <div className={styles.subTitle}>Rethink document processing</div>
      </div>
      <div className={styles.heroImageContainer}>
        <img
          src={imageData}
          alt="drawing of people analyzing a very large document"
          className={styles.heroImage}
        />
      </div>
    </section>
  );
};

export default SectionHero;

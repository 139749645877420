import React, { useState } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import Navigation from '../components/Navigation';
import SectionHero from '../components/SectionHero';
import SectionStuck from '../components/SectionStuck';
import SectionIntake from '../components/SectionIntake';
import SectionWhere from '../components/SectionWhere';
import SectionUnique from '../components/SectionUnique';
import SectionWhyNoesis from '../components/SectionWhyNoesis';
import SectionWhyDrogevate from '../components/SectionWhyDrogevate';
import SectionTeam from '../components/SectionTeam';
import SectionContact from '../components/SectionContact';
import ModalDemo from '../components/ModalDemo';
import Footer from '../components/Footer';
import CookieConsent from "react-cookie-consent";

const IndexPage = () => {
  let [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Navigation openModal={setIsModalOpen} />
      <Layout>
        <SEO title="Drogevate Solutions" />
        <SectionHero />
        <SectionStuck />
        <SectionIntake />
        <SectionWhere />
        <SectionUnique />
        <SectionWhyNoesis />
        <SectionWhyDrogevate />
        <SectionTeam />
        <SectionContact />
        <div style={isModalOpen ? { display: 'block' } : { display: 'none' }}>
          <ModalDemo openModal={setIsModalOpen} />
        </div>
      </Layout>
      <Footer />
      <CookieConsent
        enableDeclineButton
        buttonText = "Accept"
        declineButtonText = "Decline"
        cookieName={"gatsby-gdpr-google-analytics"} style={
        { borderBottom: 0,
        bottom: 0,
        top: 'auto',
        background: 'rgb(255,255,255)',
        fontSize: 'small',
        color: 'black',
         textAlign: 'center',
         fontWeight: '400',
        boxShadow: '0 -1px 3px #eaf0f6',

        }
      } buttonStyle={{ color: "#fff", fontSize: "13px", background: '#3c64c2', borderRadius : '4px' }}
        declineButtonStyle = {{ color: "#3c64c2", fontSize: "13px", background: '#fff', borderRadius : '4px', border: 'solid', borderWidth: 'thin' }}
        flipButtons = "true"
      >
        This website stores cookies on your computer to collect information about how you interact with our website
        and allow us to remember you. We use this information in order to improve and customize your browsing experience
        and for analytics and metrics about our visitors both on this website and other media,
        along with re-targeting advertisements. To find out more about the cookies we use, see our <a target="_blank" href="/privacy-policy">Privacy Policy</a>.
      </CookieConsent>

    </>
  );
};

export default IndexPage;

import React from 'react';
import styles from './SectionTeam.module.scss';
import { StaticQuery, graphql } from 'gatsby';

import CardTeam from '../CardTeam';

export const squareFragment = graphql`
  fragment squareFragment on File {
    childImageSharp {
      fluid(maxWidth: 260, maxHeight: 260) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    anil: file(relativePath: { eq: "anil_bansal.jpg" }) {
      ...squareFragment
    }
    ali: file(relativePath: { eq: "syed_ali.jpg" }) {
      ...squareFragment
    }
    amin: file(relativePath: { eq: "saeed_amin.jpg" }) {
      ...squareFragment
    }
    sourabh: file(relativePath: { eq: "saurabh_goyal.jpg" }) {
      ...squareFragment
    }
  }
`;

const SectionTeam = () => {
  return (
    <section className={`${styles.SectionTeam} section`}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>
          Meet the <span className={`green`}>Team</span>
        </h2>
        <div className={styles.subTitle}>
          Drogevate brings together a team that has completed scores of
          successful software development and system implementation projects for
          Pharma. From complex global system implementations to customized
          solutions we have a combined experience of 60+ years. We earned our
          spurs in pharmacovigilance, clinical and regulatory domains. Our
          passion remains problem solving, developing products and solutions
          that fulfill the promises we commit to.
        </div>
      </div>
      <div className={`${styles.flexContainer} flex-container`}>
        <StaticQuery
          query={query}
          render={data => (
            <div className={styles.cardContainer}>
              <CardTeam
                className={styles.card}
                name="Anil Bansal"
                title="Founder & Director"
                subTitle="Vice President - Technology"
                description="17+ years in incubating, designing, developing and implementing ideas as products and services. He has deep knowledge of various standards such HL7, FHIR, SDTM, SEND, R2, R3 used in healthcare and life sciences domain."
                education="B.E. Gold Medalist from Manipal Institute of Technology, Manipal"
                image={data.anil.childImageSharp.fluid}
              />
              <CardTeam
                className={styles.card}
                name="Syed Ali"
                title="Founder"
                subTitle='&nbsp;'
                description="17+ years of experience in Drug Safety implementations. Is a subject matter expert with expertise in developing integration solutions using ICH E2B and HL7."
                education="B.Tech from Jamia Millia Islamia University, Delhi"
                image={data.ali.childImageSharp.fluid}
              />
              <CardTeam
                className={styles.card}
                name="Saurabh Goyal"
                title="Founder & Director"
                subTitle="Vice President - Operations"
                description="22+ years of Life Sciences industry experience. In-depth knowledge of various business processes including drug safety, drug development, regulatory submissions and compliance."
                education="MCA from Kurukshetra University, Kurukshetra"
                image={data.sourabh.childImageSharp.fluid}
              />
              <CardTeam
                className={styles.card}
                name="Saeed Amin"
                title="Mentor & Advisor"
                subTitle="Strategy"
                description="24+ years experience leadership roles in Life Sciences quality, IT and data management. In-depth knowledge of program management, drug safety, drug development, regulatory submissions, training and compliance."
                education="B. Comm. (Honours) from University of Ottawa, Canada"
                image={data.amin.childImageSharp.fluid}
              />
            </div>
          )}
        />
      </div>
    </section>
  );
};

export default SectionTeam;

import React from 'react';
import styles from './SectionWhyNoesis.module.scss';

import CardNoesis from '../CardNoesis';

import innovationImage from '../../images/innovation.svg';
import accuracyImage from '../../images/accuracy.svg';
import roiImage from '../../images/roi.svg';

const SectionWhyNoesis = () => {
  return (
    <section className={`${styles.SectionWhyNoesis} section`}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>
          Why <span className={`green`}>noesis</span>?
        </h2>
      </div>
      <div className={`${styles.flexContainer} flex-container`}>
        <CardNoesis
          image={innovationImage}
          text="Industry is rapidly seeking innovative solutions, which deliver with precision. Noesis platform's is built using proprietary Data extraction and standardization techniques, infused with Microsoft's Azure blueprint. It is intended to provide industry with a true cloud solution, which enables them to See, Validate and Adapt to latest technologies with a quick turn around and measurable outcome."
          title="Innovation"
        />
        <CardNoesis
          image={accuracyImage}
          text="Noesis meticulously handles variation of layout in source templates or form, resulting in higher data extraction accuracy."
          title="Accuracy"
        />
        <CardNoesis
          image={roiImage}
          text="Reduced processing times, quickly digitized data at consistently high quality translates to more time in hand for quality review, lower processing cost and increased compliance in regulated environments."
          title="ROI"
        />
      </div>
    </section>
  );
};

export default SectionWhyNoesis;

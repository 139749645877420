import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './SectionStuck.module.scss';

import documentImage from '../../images/documents.svg';

let loaded = false;
const SectionStuck = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    loaded = true;
  }, []);
  return (
    <section className={styles.SectionStuck}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>Stuck</h2>
        <div className={styles.subTitle}>processing documents manually?</div>
      </div>
      <div className={styles.flexContainer}>
        <img
          src={documentImage}
          alt="Messy documents"
          className={`${styles.image} ${inView ? 'enterScale' : loaded ? 'wait' : ''}`}
          ref={ref}
        />
        <p className={styles.text}>
          If this resonates with you, then Drogevate’s extensible{' '}
          <span className={styles.highlight}>noesis</span> platform and{' '}
          <span className={styles.highlight}>noesis</span> intake may be the
          right solution for you. Let us help you analyze your situation and
          help you decide.
        </p>
      </div>
    </section>
  );
};

export default SectionStuck;

import React from 'react';
import styles from './ModalDemo.module.scss';

import closeIcon from '../../images/close.svg';

const ModalDemo = ({ openModal }) => {
  return (
    <div className={`${styles.ModalDemo} section`}>
      <div>
        <button className={styles.closeButton} onClick={() => openModal(false)}>
          <img src={closeIcon} alt="Cross icon" className={styles.closeIcon} />
        </button>
      </div>
      <div className="flex-container">
        <div className={styles.textContainer}>
          <p className={styles.text}>
            Everything you need to confidently manage your documents.
          </p>
        </div>
        <div className={styles.formContainer}>
          <form
            className={styles.form}
            name="request"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="name"
          >
            <input type="hidden" name="form-name" value="request" />
            <div className="field">
              <input
                className={styles.input}
                type="text"
                name="jobTitle"
                required
                aria-label="Job Title"
                placeholder="Job Title"
              />
            </div>
            <div className={styles.hide}>
              <label htmlFor="name">Do not fill this</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field">
              <input
                className={styles.input}
                type="email"
                name="email"
                required
                aria-label="Email"
                placeholder="Email"
              />
            </div>
            <div className="field">
              <input
                className={styles.input}
                type="tel"
                name="phone"
                required
                aria-label="Phone"
                placeholder="Phone"
              />
            </div>

            <div className={styles.actions}>
              <input
                type="submit"
                value="Send Message"
                className={styles.submit}
                aria-label="Submit Form"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalDemo;

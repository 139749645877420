import React from 'react';
import styles from './CardSmall.module.scss';

const CardSmall = ({
  icon = '//via.placeholder.com/50',
  heading = '',
  description = '',
  layout = 'right',
  alt = '',
  className = '',
}) => {
  return (
    <div
      className={`${styles.CardSmall} ${
        layout === 'right' ? styles.right : styles.left
      } ${className}`}
    >
      <div className={styles.textContainer}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={icon} alt={alt} />
      </div>
    </div>
  );
};

export default CardSmall;

import React from 'react';
import styles from './CardTeam.module.scss';

import Image from 'gatsby-image';

const CardTeam = ({
  image = '//via.placeholder.com/200',
  name = '',
  title = '',
  subTitle = '',
  description = '',
  education = '',
  className = '',
}) => {
  return (
    <div className={`${styles.CardTeam} ${className}`}>
      <Image fluid={image} className={styles.image} />
      <div className={styles.title}>{title}</div>
       <div className={styles.name}>{subTitle}</div>
      <div className={styles.name}>{name}</div>
      <p className={styles.description}>{description}</p>
      <p className={styles.education}>{education}</p>
    </div>
  );
};

export default CardTeam;

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './SectionWhyDrogevate.module.scss';
import { StaticQuery, graphql } from 'gatsby';

import CardDrogevate from '../CardDrogevate';

export const imageFragment = graphql`
  fragment imageFragment on File {
    childImageSharp {
      fluid(maxWidth: 320, maxHeight: 226) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "expert.png" }) {
      ...imageFragment
    }
    image2: file(relativePath: { eq: "software.png" }) {
      ...imageFragment
    }
    image3: file(relativePath: { eq: "consulting.png" }) {
      ...imageFragment
    }
    image4: file(relativePath: { eq: "quality.png" }) {
      ...imageFragment
    }
  }
`;

let loaded = false;

const SectionWhyDrogevate = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    loaded = true;
  }, []);
  return (
    <section className={`${styles.SectionWhyDrogevate} section`}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>
          Why <span className={styles.green}>Drogevate</span>?
        </h2>
        <div className={styles.subTitle} ref={ref}>
          Drogevate brings together a team that has completed scores of
          successful software development and system implementation projects for
          Healthcare and Life Sciences industry with a combined experience of
          60+ years
        </div>
      </div>
      <div
        className={`${styles.flexContainer} flex-container ${inView ? 'enterSlide' : loaded ? 'wait' : ''}`}
      >
        <StaticQuery
          query={query}
          render={data => (
            <>
              <CardDrogevate
                title="Functional"
                text="We earned our spurs in pharmacovigilance, clinical and regulatory domains. Our passion remains problem solving, developing products and solutions and offer services that fulfill the promises we commit to."
                className={styles.card}
                image={data.image1.childImageSharp.fluid}
                alt="Written in Chalk board - expert advice"
              />
              <CardDrogevate
                title="Technology"
                text="Successfully designed, developed and deployed global solutions, particularly for Healthcare, Clinical and Pharmacovigilance."
                className={styles.card}
                image={data.image2.childImageSharp.fluid}
                alt="Written in Chalk board - software engineering"
              />
              <CardDrogevate
                title="Consulting"
                text="Successful at implementing high-value, time-sensitive global solutions time and again."
                className={styles.card}
                image={data.image3.childImageSharp.fluid}
                alt="Written in Chalk board - consulting"
              />
              <CardDrogevate
                title="Validation"
                text="Our background includes high-integrity software development for sensitive applications. We apply the best of what that taught us to our software products."
                className={styles.card}
                image={data.image4.childImageSharp.fluid}
                alt="Written in Chalk board - quality control"
              />
            </>
          )}
        />
      </div>
    </section>
  );
};

export default SectionWhyDrogevate;

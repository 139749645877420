import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './SectionWhere.module.scss';

import CardSmall from '../CardSmall';

import healthCareIcon from '../../images/hospital.svg';
import clinicalIcon from '../../images/clinic.svg';
import safetyIcon from '../../images/protection.svg';
import financeIcon from '../../images/coins.svg';
import educationIcon from '../../images/scholarship.svg';
import governmentIcon from '../../images/government.svg';

let loaded = false;
const SectionWhere = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    loaded = true;
  }, []);
  return (
    <section className={styles.SectionWhere}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>Where?</h2>
        <div className={styles.subTitle}>
          Any where you need a form to be digitized
        </div>
      </div>
      <div className={styles.flexContainer}>
        <div
          className={`${styles.cards} ${inView ? 'enterSlide' : loaded ? 'wait' : ''}`}
          ref={ref}
        >
          <div>
            <div className={styles.cardRow}>
              <CardSmall
                className={styles.card}
                heading="Healthcare"
                description="Digitizing forms from Hospitals, ACO’s and Clinics"
                icon={healthCareIcon}
                alt="Hospital plus sign"
              />
              <CardSmall
                className={styles.card}
                heading="Clinical"
                description="Extracting data from clinical trial forms"
                icon={clinicalIcon}
                layout="left"
                alt="Hospital drawing"
              />
            </div>
            <div className={styles.cardRow}>
              <CardSmall
                className={styles.card}
                heading="Safety"
                description="Adverse event intake"
                icon={safetyIcon}
                alt="Shield"
              />
              <CardSmall
                className={styles.card}
                heading="Finance"
                description="Banking forms, Financial statements and Insurance forms"
                icon={financeIcon}
                layout="left"
                alt="Coins with plant"
              />
            </div>
            <div className={styles.cardRow}>
              <CardSmall
                className={styles.card}
                heading="Government"
                description="Record management systems"
                icon={governmentIcon}
                alt="Government building"
              />
              <CardSmall
                className={styles.card}
                heading="Education"
                description="Marksheets, notes to digital content"
                icon={educationIcon}
                layout="left"
                alt="Books and graduation hat"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWhere;

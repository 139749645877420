import React from 'react';

import styles from './SectionUnique.module.scss';

import image from '../../images/unique3.svg';

const SectionUnique = () => {
  return (
    <section className={`${styles.SectionUnique} section`}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>
          What makes <span className={`yellow`}>noesis</span> unique?
        </h2>
      </div>
      <ul visuallyhidden="true">
        <li>Any Volume</li>
        <li>Any Form, Any Language</li>
        <li>Any output but E2B R2, R3 are standard</li>
        <li>Any change quickly adopted</li>
        <li>Not just any accuracy, 95%</li>
        <li>Insert into any database</li>
      </ul>
      <img
        src={image}
        alt="6 points what makes drogevate unique"
        className={styles.image}
        loading="lazy"
      />
    </section>
  );
};

export default SectionUnique;

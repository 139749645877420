import React from 'react';
import styles from './CardNoesis.module.scss';

const CardNoesis = ({
  image = '//via.placeholder.com/300',
  text = '',
  alt = '',
  title = '',
}) => {
  return (
    <div className={styles.CardNoesis} tabIndex="1">
      <div className={styles.cardContainer}>
        <div className={styles.front}>
          <img src={image} alt={alt} className={styles.image} />
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.back}>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default CardNoesis;

import React from 'react';
import styles from './SectionIntake.module.scss';

import firstImage from '../../images/1.svg';
import secondImage from '../../images/2.svg';
import thirdImage from '../../images/3.svg';
import fourthImage from '../../images/4.svg';
import fifthImage from '../../images/5.svg';
import pathImage from '../../images/path.png';

const SectionIntake = () => {
  return (
    <section className={`${styles.SectionIntake} section`}>
      <div className={styles.titleContainer}>
        <h2 className={`title`}>
          <span className={`green`}>noesis</span> framework
        </h2>
      </div>
      <div className={`${styles.flexContainer} flex-container`}>
        <div className={styles.gridContainer}>
          <div className={styles.pathContainer}>
            <img src={pathImage} alt="dotted path connecting images below" className={styles.path} />
          </div>
          <div className={`${styles.first} ${styles.card}`}>
            <img src={firstImage} alt="Drawn people standing near lightbulb sign" className={styles.image} />
            <span className={`${styles.number} ${styles.num1}`}>1</span>
            <div className={styles.imageTitle}>Define</div>
            <div className={styles.imageDescription}>
              Tell us what information you need from your documents
            </div>
          </div>
          <div className={`${styles.second} ${styles.card}`}>
            <img src={secondImage} alt="Drawn people writing on pie char on sign board" className={styles.image} />
            <span className={`${styles.number} ${styles.num2}`}>2</span>
            <div className={styles.imageTitle}>Rules</div>
            <div className={styles.imageDescription}>
              Define any business rules to the extracted data
            </div>
          </div>
          <div className={`${styles.third} ${styles.card}`}>
            <img src={thirdImage} alt="Drawn people using laptop and loudspeaker need talk bubbles sign board" className={styles.image} />
            <span className={`${styles.number} ${styles.num3}`}>3</span>
            <div className={styles.imageTitle}>Specify</div>
            <div className={styles.imageDescription}>
              Tell us the format of the output you prefer
            </div>
          </div>
          <div className={`${styles.fourth} ${styles.card}`}>
            <img src={fourthImage} alt="Drawn people thinking near puzzle piece sign" className={styles.image} />
            <span className={`${styles.number} ${styles.num4}`}>4</span>
            <div className={styles.imageTitle}>Review</div>
            <div className={styles.imageDescription}>
              Review your defined and mapped outputs
            </div>
          </div>
          <div className={`${styles.fifth} ${styles.card}`}>
            <img src={fifthImage} alt="Drawn people excited near gears sign" className={styles.image} />
            <span className={`${styles.number} ${styles.num5}`}>5</span>
            <div className={styles.imageTitle}>Process</div>
            <div className={styles.imageDescription}>
              See your data effortlessly digitized
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionIntake;

import React from 'react';
import Obfuscate from 'react-obfuscate';

import styles from './SectionContact.module.scss';

import emailImage from '../../images/email.svg';

const SectionContact = () => {
  return (
    <section className={styles.SectionContact} id="contact">
      <div className={styles.titleContainer}>
        <h2 className={`title`}>Contact</h2>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.centerContainer}>
          <div className={styles.contactContainer}>
            <div className={styles.contactInfo}>
              <div className={styles.header}>For All your PV needs</div>
              <div className={styles.emailContainer}>
                <img
                  src={emailImage}
                  alt="An envelope with @"
                  className={styles.emailIcon}
                />
                <Obfuscate email="sales@drogevate.com" />
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <form
              className={styles.form}
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="fullname"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="field">
                <input
                  className={styles.input}
                  type="text"
                  name="name"
                  required
                  aria-label="Name"
                  placeholder="Name"
                />
              </div>
              <div className={styles.hide}>
                <label htmlFor="fullname">Do not fill this</label>
                <input type="text" name="fullname" id="fullname" />
              </div>
              <div className="field">
                <input
                  className={styles.input}
                  type="email"
                  name="email"
                  required
                  aria-label="Email"
                  placeholder="Email"
                />
              </div>
              <div className="field">
                <input
                  className={styles.input}
                  type="tel"
                  name="phone"
                  required
                  aria-label="Phone"
                  placeholder="Phone"
                />
              </div>
              <div className="field">
                <input
                  className={styles.input}
                  type="text"
                  name="jobTitle"
                  required
                  aria-label="Job Title"
                  placeholder="Job Title"
                />
              </div>
              <div className="field">
                <input
                  className={styles.input}
                  type="text"
                  name="organisation"
                  required
                  aria-label="Organisation"
                  placeholder="Organisation"
                />
              </div>
              <div className="field">
                <textarea
                  className={styles.input}
                  name="message"
                  rows="3"
                  required
                  aria-label="Message"
                  placeholder="Message"
                />
              </div>
              <div className={styles.actions}>
                <input
                  type="submit"
                  value="Send Message"
                  className={styles.submit}
                  aria-label="Submit Form"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionContact;

import React from 'react';
import styles from './CardDrogevate.module.scss';

import Image from 'gatsby-image';

const CardDrogevate = ({
  image = '//via.placeholder.com/250x180',
  text = '',
  title = '',
  className = '',
}) => {
  return (
    <div className={`${styles.CardDrogevate} ${className}`}>
      <Image fluid={image} />
      <p className={styles.text}>{text}</p>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default CardDrogevate;
